import { parse } from 'qs';
import { get, isEmpty } from '@/lib/helpers';
import { updateLayoutConfig } from '@/lib/layout_config';
import { layoutConfig } from '@/lib/layout_consts';
export const magicLinkRoutes = {
  // TODO: Change name to get from layout config
  path: '/ml/:linkId',
  name: 'home-ml',
  component: () =>
    import(
      /* webpackChunkName: "ml-app-layout" */ '@/layouts/MagicLinkLayout.vue'
    ),
  beforeEnter: (to: any, from: any, next: any) => {
    updateLayoutConfig('MAGIC_LINK_LAYOUT');
    const searchString = window.location.search;
    let query = {};
    if (searchString) {
      query = parse(searchString, { ignoreQueryPrefix: true });
      history.replaceState(
        null,
        '',
        window.location.pathname + window.location.hash
      );
    }
    if (!isEmpty(query)) {
      next({
        ...to,
        ...(!isEmpty(query) && { query }),
      });
    } else {
      next();
    }
  },
  children: [
    {
      path: 'terms-and-conditions',
      name: 'termsAndConditions-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-tnc" */ '@/views/magicLink/TermsAndConditions.vue'
        ),
    },
    {
      path: 'sign-documents-dmi',
      name: 'signDocumentsDmi-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-sign-dmi" */ '@/views/magicLink/signDocuments/dmi/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-rbl',
      name: 'signDocumentsRbl-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-sign-rbl" */ '@/views/magicLink/signDocuments/rbl/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-incred',
      name: 'signDocumentsIncred-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-sign-incred" */ '@/views/magicLink/signDocuments/incred/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-fullerton',
      name: 'signDocumentsFullerton-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-sign-ft" */ '@/views/magicLink/signDocuments/fullerton/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-tapstart',
      name: 'signDocumentsTapstart-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-sign-ts" */ '@/views/magicLink/signDocuments/tapstart/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-cholamandalam',
      name: 'signDocumentsCholamandalam-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-sign-cm" */ '@/views/magicLink/signDocuments/cholamandalam/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-lendbox',
      name: 'signDocumentsLendbox-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-sign-lb" */ '@/views/magicLink/signDocuments/lendbox/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-muskmelon',
      name: 'signDocumentsMuskmelon-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-sign-mm" */ '@/views/magicLink/signDocuments/muskmelon/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-creditsaison',
      name: get(
        layoutConfig,
        'MAGIC_LINK_LAYOUT.routes.signDocumentsCreditSaison',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-sign-cs" */ '@/views/magicLink/signDocuments/credit_saison/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-hdbfs',
      name: get(
        layoutConfig,
        'MAGIC_LINK_LAYOUT.routes.signDocumentsHdbfs',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-sign-hdbfs" */ '@/views/magicLink/signDocuments/hdbfs/SignDocuments.vue'
        ),
    },
    {
      path: 'e-mandate',
      name: 'setupEmandate-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-mandate" */ '@/views/magicLink/SetupEmandate.vue'
        ),
    },
    {
      path: 'other-details',
      name: 'captureOtherDetails-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-mandate" */ '@/views/magicLink/CaptureOtherDetails.vue'
        ),
    },
    {
      path: 'okyc',
      name: 'okyc-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-okyc" */ '@/views/magicLink/OfflineKyc.vue'
        ),
    },
    {
      path: 'beneficiary-details',
      name: 'beneficiaryDetails-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-bene-details" */ '@/views/magicLink/BeneficiaryDetails.vue'
        ),
    },
    {
      path: 'complete-kyc',
      name: 'completeKyc-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-bene-details" */ '@/views/magicLink/CompleteKycLanding.vue'
        ),
    },
    {
      path: 'moneytap-tnc',
      name: 'moneytapTnc-ml',
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-mt-tnc" */ '@/views/magicLink/MoneytapTnc.vue'
        ),
    },
    {
      path: 'limit-increase',
      name: get(layoutConfig, 'MAGIC_LINK_LAYOUT.routes.limitIncrease', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-lmt-increase" */ '@/views/magicLink/LimitIncrease.vue'
        ),
    },
    {
      path: 'hyperverge-okyc',
      name: get(layoutConfig, 'MAGIC_LINK_LAYOUT.routes.hypervergeOkyc', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-hyperverge-okyc" */ '@/views/magicLink/HypervergeOkyc.vue'
        ),
    },
    {
      path: 'upload-documents',
      name: get(layoutConfig, 'MAGIC_LINK_LAYOUT.routes.uploadDocuments', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-upload-docs" */ '@/views/magicLink/UploadDocuments.vue'
        ),
    },
    {
      path: 'submit-bank-statement',
      name: get(
        layoutConfig,
        'MAGIC_LINK_LAYOUT.routes.submitBankStatement',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-submit-bank-statement" */ '@/views/magicLink/SubmitBankStatement.vue'
        ),
    },
    {
      path: 'cap-upload',
      name: get(layoutConfig, 'MAGIC_LINK_LAYOUT.routes.capUpload', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-bank-statement" */ '@/views/magicLink/CapUpload.vue'
        ),
    },
    {
      path: 'upload-salary-slips',
      name: get(layoutConfig, 'MAGIC_LINK_LAYOUT.routes.uploadSalarySlips', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-upload-salary-slips" */ '@/views/UploadSalarySlips.vue'
        ),
    },
    {
      path: 'submit-bank-proof',
      name: get(layoutConfig, 'MAGIC_LINK_LAYOUT.routes.submitBankProof', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-bank-statement" */ '@/views/SubmitBankProof.vue'
        ),
    },
    {
      path: 'dkyc',
      name: get(layoutConfig, 'MAGIC_LINK_LAYOUT.routes.dkyc', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "ml-dkyc" */ '@/views/magicLink/DigiKyc.vue'
        ),
    },
  ],
};
