export const partnerBankConfig = {
  RBL: {
    bankCode: 'RBL',
    bankName: 'RBL Bank',
    bankNameAbbreviated: 'RBL',
    mtBankImageUrl: require('@/assets/images/mt_rbl_logo.png'),
    bankCardPinSetUrl: '',
    bankCreditCardArt: require('@/assets/images/ic_rbl_imps_check_1.png'),
    bankLogoUrl: require('@/assets/images/rbl_logo.png'),
    bankLogoUrlMonochrome:
      'https://placeholdit.imgix.net/~text?txtsize=33&txt=BankLogoMonochrome1&w=72&h=72',
    minimumAge: 23,
    maximumAge: 65,
    minimumIncome: 20000,
    supportedCities: [
      'Ahmedabad',
      'Ambala',
      'Anand',
      'Bangalore',
      'Bhopal',
      'Bharuch',
      'Chandigarh',
      'Chennai',
      'Coimbatore',
      'Dehradun',
      'Delhi',
      'Erode',
      'Faridabad',
      'Gandhinagar',
      'Ghaziabad',
      'Greater Noida',
      'Gurgaon',
      'Haridwar',
      'Hyderabad',
      'Indore',
      'Jaipur',
      'Jodhpur',
      'Kochi',
      'Kolhapur',
      'Kolkata',
      'Lucknow',
      'Mangalore',
      'Mohali',
      'Mumbai',
      'Nagpur',
      'Nashik',
      'Navi Mumbai',
      'New Delhi',
      'Noida',
      'Panchkula',
      'Pune',
      'Raipur',
      'Rajkot',
      'Salem',
      'Secunderabad',
      'Surat',
      'Thane',
      'Tirupathi',
      'Tiruchirappalli',
      'Vadodara',
      'Vijayawada',
      'Vishakapatnam',
    ],
    productOfferings: [
      {
        productCode: 'credit_card',
        productName: 'MoneyTap Card',
        productTitle: 'Credit Card Benefits',
        productDescription:
          'You can use your enture credit line limit as a credit card.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description: `Pay interest only on the amount you transfer into your bank account. If you dont use the credit line, you never have to pay any interest.`,
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title: 'Instant Limit Top-Up. No paperwork.',
            description: `Whenever you pay back an EMI, your limit gets an instant top for the same amount. Use the money again without paper work.`,
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
          {
            title: 'Instant Money Transfer',
            description: `Whenever you have a cash need, simply use the MoneyTap app to transfer money into your bank account instantly.`,
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title: 'Credit Card',
            description: `MoneyTap provides you with a credit card which is accepted everywhere.`,
            iconUrl: require('@/assets/images/ic_master_card.png'),
          },
          {
            title: '100% Cash Transfer',
            description: `You can transfer 100% of your credit card limit into your bank account. Only MoneyTap offers this feature.`,
            iconUrl: require('@/assets/images/ic_cash.png'),
          },
          {
            title: 'Welcome Benefits',
            description: `2000 Reward Points on activation of card within 30 days.`,
            iconUrl: require('@/assets/images/ic_points.png'),
          },
          {
            title: 'Instant Discount on Grofers',
            description:
              '10% instant discount up to total of Rs. 300/- in a month using code “MONEYTAP” on Grofers app (on max of 2 purchases)',
            iconUrl: require('@/assets/images/ic_grofers.png'),
          },
          {
            title: 'Cashback on Zomato',
            description:
              '20% cashback up to Rs. 200/- in a month (on any number of purchases)',
            iconUrl: require('@/assets/images/ic_zomato.png'),
          },
          {
            title: 'Free Movie Tickets',
            description:
              'Get 1+1 free movie ticket every month up to Rs. 200/- at BookMyShow for Saturday & Sunday shows.',
            iconUrl: require('@/assets/images/ic_bms.png'),
          },
          {
            title: 'Reward Points On All Purchases',
            description:
              'Earn 1 reward point for every Rs. 100 spent offline and earn 2 reward points for online purchases.',
            iconUrl: require('@/assets/images/ic_points.png'),
          },
          {
            title: 'Milestone Benefit',
            description:
              'Rs. 1000 Amazon Voucher on reaching spends of Rs. 2.5 lac in the year sent by Vouchagram through SMS/Email',
            iconUrl: require('@/assets/images/ic_amazon.png'),
          },
        ],
      },
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest',
            description: `Pay interest only on the amount you want to borrow.
                         <b>No interest charged</b> if you don’t borrow any money from your credit line.`,
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<span style="color: var(--primary)">Instant</span> Money Transfer',
            description: `Whenever you have a cash need,
                         simply use the MoneyTap app to transfer money into your bank account instantly.`,
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Instant Limit Top-Up. <span style="color: var(--primary)">No paperwork</span>',
            description: `Whenever you pay back an EMI,
                         your limit gets an instant top-up for the same amount. Use the money again.`,
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      PostGraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    documentGroups: {
      self_current_address_proof: {
        driving_license: '',
        passport: '',
        // aadhaar_card: '',
        electricity_bill: '',
        telephone_bill: '',
        mobile_postpaid_bill: '',
        piped_gas_bill: '',
        broadband_bill: '',
        bank_statement: '',
        voter_id: '',
      },
      self_permanent_address_proof: {
        passport: 'SALARIED',
        // aadhaar_card: 'SALARIED'
      },
      relationship_proof: {
        marriage_certificate: '',
        birth_certificate: '',
        passport: '',
        pan_card: '',
        voter_id: '',
        // aadhaar_card: '',
        ration_card: '',
      },
      relative_current_address_proof: {
        driving_license: '',
        passport: '',
        voter_id: '',
        // aadhaar_card: '',
        ration_card: '',
        electricity_bill: '',
        telephone_bill: '',
        mobile_postpaid_bill: '',
        water_bill: '',
        piped_gas_bill: '',
        broadband_bill: '',
        gas_connection: '',
        bank_statement: '',
      },
      id_proof: {
        pan_card: '',
        driving_license: '',
        passport: '',
      },
    },
    emiInformation: {
      CASH_LINE: [],
      CONSUMER_FINANCE: [],
      FAST_CASH: [],
      DEFAULT: [],
    },
  },
  DMI: {
    bankCode: 'DMI',
    bankName: 'DMI Finance',
    bankNameAbbreviated: 'DMI',
    mtBankImageUrl: require('@/assets/images/mt_dmi_logo.png'),
    bankLogoUrl: require('@/assets/images/dmi_logo.png'),
    bankLogoUrlMonochrome:
      'https://placeholdit.imgix.net/~text?txtsize=33&txt=BankLogoMonochrome1&w=72&h=72',
    minimumAge: 23,
    maximumAge: 56,
    minimumIncome: 25000,
    repaymentConfig: {
      test: 'rzp_test_t1CwKeSXwKQC9B',
      live: 'rzp_live_UibQ6IINcwbUFd',
    },
    emandateConfig: {
      test: 'rzp_test_u3QQqPLrgMi0TA',
      // test: 'rzp_live_G7f1p52l8AJBLB',
      live: 'rzp_live_G7f1p52l8AJBLB',
    },
    autoDebitConfig: {
      test: 'rzp_test_u3QQqPLrgMi0TA',
      live: 'rzp_live_5wZMMqvaRuKVDi',
    },
    supportedCities: [
      'Ahmedabad',
      'Aurangabad',
      'Bangalore',
      'Bhopal',
      'Bhubaneswar',
      'Chandigarh',
      'Chennai',
      'Coimbatore',
      'Dehradun',
      'Faridabad',
      'Ghaziabad',
      'Greater Noida',
      'Guntur',
      'Gurgaon',
      'Guwahati',
      'Noida',
      'Nagpur',
      'Delhi',
      'Hooghly',
      'Howrah',
      'Indore',
      'Jodhpur',
      'Kanpur',
      'Kolkata',
      'Kochi',
      'Lucknow',
      'Mumbai',
      'Mysuru',
      'Navi Mumbai',
      'Nashik',
      'Mumbai',
      'New Delhi',
      'Rajkot',
      'Surat',
      'Thane',
      'Pune',
      'Vadodara',
      'Vijayawada',
      'Vishakapatnam',
      'Jaipur',
      'Secunderabad',
    ],
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description: `Pay interest only on the amount you want to borrow.
                         <b>No interest charged</b> if you don't borrow any money from your credit line.`,
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<span style="color: var(--primary)">Instant</span> Money Transfer',
            description: `Whenever you have a cash need, simply
                         use the MoneyTap app to transfer money into your bank account instantly.`,
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <span style="color: var(--primary)">No paperwork</span>',
            description: `Whenever you close a loan,
                         your limit gets an instant top-up for the same amount. Use the money again.`,
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
      },
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    documentGroups: {
      id_proof: {
        pan_card: '',
        driving_license: '',
        passport: '',
      },
      residence_address_proof: {
        // aadhaar_card: '',
        passport: '',
        driving_license: '',
        voter_id: '',
      },
    },
    emiInformation: {
      CASH_LINE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charges',
          value: '2% PM on an amount of EMI Outstanding as on that date',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value: `Up to 3% on outstanding principal +
           applicable taxes + overdue interest at 2% per month on unpaid EMI`,
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: `Basis the withdrawal amount:
          199-299 for 3k,
          399-449 for5k,
          499-699 for 10k,
          300-750 for 15k to 25k,
          2% to 3% for >25k`,
          width: 'full',
        },
      ],
      CONSUMER_FINANCE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charges',
          value: '2% PM on an amount of EMI Outstanding as on that date',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value: `Up to 3% on outstanding principal +
           applicable taxes + overdue interest at 2% per month on unpaid EMI`,
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: `Basis the withdrawal amount:
          199-299 for 3k,
          399-449 for5k,
          499-699 for 10k,
          300-750 for 15k to 25k,
          2% to 3% for >25k`,
          width: 'full',
        },
      ],
      FAST_CASH: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charges',
          value: '2% PM on an amount of EMI Outstanding as on that date',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value: `Up to 3% on outstanding principal
           + applicable taxes + overdue interest at 2% per month on unpaid EMI`,
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: `Basis the withdrawal amount:
          199-299 for 3k,
          399-449 for5k,
          499-699 for 10k,
          300-750 for 15k to 25k,
          2% to 3% for >25k`,
          width: 'full',
        },
      ],
      DEFAULT: [],
    },
    actions: [
      {
        code: 'interested',
        title: 'Process my application',
      },
      {
        code: 'notInterested',
        title: 'Not Interested',
      },
      {
        code: 'withdrawal',
        title: 'Get Money',
      },
      {
        code: 'fund_transfer',
        title: 'Transfer Funds',
      },
    ],
  },
  MUSKMELON: {
    bankCode: 'MUSKMELON',
    bankName: 'Apollo Finvest (India) Limited',
    bankNameAbbreviated: 'MUSKMELON',
    mtBankImageUrl: require('@/assets/images/mt_mm_logo.png'),
    bankLogoUrl: require('@/assets/images/mt_mm_logo.png'),
    bankLogoUrlMonochrome:
      'https://placeholdit.imgix.net/~text?txtsize=33&txt=BankLogoMonochrome1&w=72&h=72',
    minimumAge: 21,
    maximumAge: 35,
    minimumIncome: 15000,
    repaymentConfig: {
      test: 'rzp_test_ZixJInbawIYKdi',
      // test: 'rzp_live_kJK2xJxRpGUsl0',
      live: 'rzp_live_kJK2xJxRpGUsl0',
    },
    emandateConfig: {
      test: 'rzp_test_fBZUhViYvGaVB7',
      live: 'rzp_live_5wZMMqvaRuKVDi',
    },
    autoDebitConfig: {
      test: 'rzp_test_fBZUhViYvGaVB7',
      live: 'rzp_live_5wZMMqvaRuKVDi',
    },
    supportedCities: [
      'Ahmedabad',
      'Aurangabad',
      'Bangalore',
      'Bhopal',
      'Bhubaneswar',
      'Chandigarh',
      'Chennai',
      'Coimbatore',
      'Delhi',
      'Faridabad',
      'Gurgaon',
      'Ghaziabad',
      'Greater Noida',
      'Guntur',
      'Gurgaon',
      'Guwahati',
      'Hyderabad',
      'Indore',
      'Jaipur',
      'Jodhpur',
      'Kanpur',
      'Kochi',
      'Lucknow',
      'Secunderabad',
      'Surat',
      'Kolkata',
      'Mumbai',
      'Mysuru',
      'Noida',
      'Nagpur',
      'Nashik',
      'Navi Mumbai',
      'New Delhi',
      'Pune',
      'Rajkot',
      'Thane',
      'Vadodara',
      'Vijayawada',
      'Vishakapatnam',
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description: `Pay interest only on the amount you want to borrow. <b>No interest charged</b>
      if you don't borrow any money from your credit line.`,
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money\
        into your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a \
  loan/credit card currently.',
        channelPartners: [
          {
            code: 'MUSKMELON',
            name: 'MUSKMELON',
            channel: 'IMPS',
          },
          {
            // TODO: change icons and images
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher ' +
                  'for one or multiple transactions on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted\
            to select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be\
            credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    documentGroups: {
      id_proof: {
        // aadhaar_card: '',
        voter_id: '',
        pan_card: '',
        driving_license: '',
        passport: '',
      },
      self_current_address_proof: {
        // aadhaar_card: '',
        passport: '',
        driving_license: '',
        voter_id: '',
        // tax_receipt: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        telephone_bill: '',
        water_bill: '',
        gas_connection: '',
      },
    },
    emiInformation: {
      CASH_LINE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 650 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value: '2% PM on an amount of EMI Outstanding as on that date',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: `Basis the withdrawal amount: Rs. 499 for 5K to 10K,
          Rs. 699 for 10001 to 15k,
          Rs. 899 for 15001 to 20k,
          Rs. 1299 for 20001 to 25k,
          Rs. 1599 for 25001 to 30k,
          Rs. 1999 for 30001 to 40k`,
          width: 'full',
        },
      ],
      CONSUMER_FINANCE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 650 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value: '2% PM on an amount of EMI Outstanding as on that date',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: `Basis the withdrawal amount: Rs. 799 for 10K,
           Rs. 1199 for 20K, Rs. 1499 for 25K and Rs. 1599 for 30K`,
          width: 'full',
        },
      ],
      FAST_CASH: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 650 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value: '2% PM on an amount of EMI Outstanding as on that date',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: `Basis the withdrawal amount: Rs. 499 for 5k to 10K,
          Rs. 699 for 10001 to 15K, Rs. 899 to Rs. 1050 for 15001 to 20K,
          Rs. 1050 to Rs. 1299 for 20001 to 25K, Rs. 1050 to Rs. 1599 for 25001 to 30K,
          Rs. 1999 to 3.5% for 30001 to 150000`,
          width: 'full',
        },
      ],
      DEFAULT: [],
    },
  },
  INCRED: {
    bankCode: 'INCRED',
    bankName: 'InCred',
    bankNameAbbreviated: 'INCRED',
    mtBankImageUrl: require('@/assets/images/mt_incred_logo.png'),
    bankLogoUrl: require('@/assets/images/incred_standalone.png'),
    bankLogoUrlMonochrome:
      'https://placeholdit.imgix.net/~text?txtsize=33&txt=BankLogoMonochrome1&w=72&h=72',
    minimumAge: 23,
    maximumAge: 62,
    minimumIncome: 15000,
    repaymentConfig: {
      test: 'rzp_test_THVkwRzkbRshNj',
      // test: 'rzp_live_3JB2yI2OovW437',
      live: 'rzp_live_3JB2yI2OovW437',
    },
    emandateConfig: {
      test: 'rzp_test_jIJsOLjGtfpyHk',
      live: 'rzp_live_JDKowN4N0wDcDU',
    },
    autoDebitConfig: {
      test: 'rzp_test_jIJsOLjGtfpyHk',
      live: 'rzp_live_JDKowN4N0wDcDU',
    },
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a\
    loan/credit card currently.',
        channelPartners: [
          {
            code: 'INCRED',
            name: 'INCRED',
            channel: 'IMPS',
          },
          {
            // TODO: change images and icons
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher for one or multiple transactions\
            on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have an \
          existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number \
          when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted to \
          select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be \
          credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    supportedCities: [
      'Ahmedabad',
      'Aurangabad',
      'Bangalore',
      'Bhopal',
      'Bhubaneswar',
      'Chandigarh',
      'Chennai',
      'Coimbatore',
      'Delhi',
      'Faridabad',
      'Gurgaon',
      'Ghaziabad',
      'Greater Noida',
      'Guntur',
      'Gurgaon',
      'Guwahati',
      'Hyderabad',
      'Indore',
      'Jaipur',
      'Jodhpur',
      'Kanpur',
      'Kochi',
      'Lucknow',
      'Secunderabad',
      'Surat',
      'Kolkata',
      'Mumbai',
      'Mysuru',
      'Noida',
      'Nagpur',
      'Nashik',
      'Navi Mumbai',
      'New Delhi',
      'Pune',
      'Rajkot',
      'Thane',
      'Vadodara',
      'Vijayawada',
      'Vishakapatnam',
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    documentGroups: {
      id_proof: {
        pan_card: '',
        // aadhaar_card: '',
        voter_id: '',
        passport: '',
      },
      self_current_address_proof: {
        // aadhaar_card: '',
        passport: '',
        bank_statement: '',
        voter_id: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        water_bill: '',
        gas_connection: '',
      },
    },
    emiInformation: {
      CASH_LINE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value:
            'Delayed Interest charge of 2% per month will be levied for number of days EMI payment is delayed.',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: 'Up to 6 % of the withdrawal amount + applicable taxes',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value:
            'Up to 3% on outstanding principal + applicable taxes + overdue interest at 2% per month on unpaid EMI',
          width: 'full',
        },
      ],
      CASH_LINE_BOLT: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value:
            'Delayed Interest charge of 2% per month will be levied for number of days EMI payment is delayed.',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: 'Up to 7 % of the withdrawal amount + applicable taxes',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value:
            'Up to 3% on outstanding principal + applicable taxes + overdue interest at 2% per month on unpaid EMI',
          width: 'full',
        },
      ],
      FAST_CASH: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value:
            '2% per month will be levied for number of days EMI payment is delayed.',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value:
            'Basis the withdrawal amount: 299 for 3K, 449 for 5K, 699 for 10K, 625 or \
          750 for 15K to 25K, 2.5% to 3% for greater than or equal to 25K',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value:
            'Up to 3% on outstanding principal + applicable taxes + overdue interest at 2% per month on unpaid EMI',
        },
      ],
      CONSUMER_FINANCE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value:
            '2% per month will be levied for number of days EMI payment is delayed.',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value:
            'Up to 3% on outstanding principal + applicable taxes + overdue interest at 2% per month on unpaid EMI',
        },
      ],
      DEFAULT: [],
    },
  },
  FULLERTON: {
    bankCode: 'FULLERTON',
    bankName: 'Fullerton',
    bankNameAbbreviated: 'FULLERTON',
    mtBankImageUrl: require('@/assets/images/mt_fullerton_logo.png'),
    bankLogoUrl: require('@/assets/images/mt_fullerton_logo.png'),
    bankLogoUrlMonochrome:
      'https://placeholdit.imgix.net/~text?txtsize=33&txt=BankLogoMonochrome1&w=72&h=72',
    minimumAge: 23,
    maximumAge: 62,
    minimumIncome: 15000,
    repaymentConfig: {
      test: 'rzp_test_THVkwRzkbRshNj',
      live: 'rzp_live_tPPQM7HMp4qpNC',
    },
    emandateConfig: {
      // test: 'rzp_test_jIJsOLjGtfpyHk',
      test: 'rzp_test_D0qcSmJyXFpHX6',
      live: 'rzp_live_Coer6boi1pFqLw',
    },
    autoDebitConfig: {
      test: 'rzp_test_Waj1Z9VdQ6bUxh',
      live: 'rzp_live_Coer6boi1pFqLw',
    },
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
    ],
    supportedCities: [
      'Ahmedabad',
      'Aurangabad',
      'Bangalore',
      'Bhubaneswar',
      'Bhopal',
      'Chennai',
      'Coimbatore',
      'Chandigarh',
      'Delhi',
      'Dehradun',
      'Jaipur',
      'Jodhpur',
      'Lucknow',
      'Gurgaon',
      'Guntur',
      'Guwahati',
      'Surat',
      'Indore',
      'Nashik',
      'Hyderabad',
      'Secunderabad',
      'Kolkata',
      'Kochi',
      'Kanpur',
      'Mumbai',
      'Mysuru',
      'Rajkot',
      'Nagpur',
      'Pune',
      'Vadodara',
      'Vijayawada',
      'Vishakapatnam',
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    documentGroups: {
      id_proof: {
        pan_card: '',
        // aadhaar_card: '',
        voter_id: '',
        passport: '',
        driving_license: '',
        // govt_employee_id_card: ''
      },
      self_current_address_proof: {
        // aadhaar_card: '',
        passport: '',
        bank_statement: '',
        voter_id: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        water_bill: '',
        gas_connection: '',
        piped_gas_bill: '',
        driving_license: '',
        // insurance_policy: '',
        // vehicle_registration_certificate: '',
        // employer_accomodation_allocation_letter: '',
        // municipal_tax_receipt: '',
        // property_tax_receipt: '',
        // postoffice_account_statement: '',
        // postoffice_account_passbook: '',
        // employee_address_proof_letter: '',
        // nrega_job_card: '',
        // npr_letter: '',
        // pensioner_card: '',
        // gas_bill: '',
        // govt_medical_insurance: ''
      },
    },
    emiInformation: {
      CASH_LINE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Processing Fees',
          value:
            'Basis the withdrawal amount: 299 for 3k, 449 for 5k, 699 for 10k,1000 for 15k to 25k, 5% for greater than 25K + applicable taxes',
          width: 'full',
        },
        {
          name: 'Foreclosure/Prepayment Charges',
          value: 'Not exceeding 5% of the loan outstanding + Applicable GST',
          width: 'full',
        },
        {
          name: 'Bounce/ Late Payment Charges',
          value: 'Not exceeding Rs. 500 + Applicable GST',
          width: 'half',
        },
        {
          name: 'Penal Interest Charges',
          value: 'Not exceeding 2.5% per month accrued on daily basis',
          width: 'full',
        },
        {
          name: 'Foreclosure criteria',
          value:
            'Loan cannot be foreclosed within 3 months from the Disbursement date',
          width: 'full',
        },
      ],
      CASH_LINE_BOLT: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Processing Fees',
          value: 'Up to 5 % of the withdrawal amount + applicable taxes',
          width: 'full',
        },
        {
          name: 'Foreclosure/Prepayment Charges',
          value: 'Not exceeding 5% of the loan outstanding + Applicable GST',
          width: 'full',
        },
        {
          name: 'Bounce/ Late Payment Charges',
          value: 'Not exceeding Rs. 500 + Applicable GST',
          width: 'half',
        },
        {
          name: 'Penal Interest Charges',
          value: 'Not exceeding 2.5% per month accrued on daily basis',
          width: 'full',
        },
        {
          name: 'Foreclosure criteria',
          value:
            'Loan cannot be foreclosed within 3 months from the Disbursement date',
          width: 'full',
        },
      ],
      FAST_CASH: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Processing Fees',
          value:
            'Based on disbursal amount: Not exceeding (Rs.1299 for <20k; Rs.2499 for 20k-35k) + Applicable GST',
          width: 'full',
        },
        {
          name: 'Foreclosure/Prepayment Charges',
          value: 'Not exceeding 5% of the loan outstanding + Applicable GST',
          width: 'full',
        },
        {
          name: 'Bounce/ Late Payment Charges',
          value: 'Not exceeding Rs. 500 + Applicable GST',
          width: 'half',
        },
        {
          name: 'Penal Interest Charges',
          value: 'Not exceeding 2.5% per month accrued on daily basis',
          width: 'full',
        },
        {
          name: 'Foreclosure criteria',
          value:
            'Loan cannot be foreclosed within 3 months from the Disbursement date',
          width: 'full',
        },
      ],
      CONSUMER_FINANCE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Foreclosure/Prepayment Charges',
          value: 'Not exceeding 5% of the loan outstanding + Applicable GST',
          width: 'full',
        },
        {
          name: 'Bounce/ Late Payment Charges',
          value: 'Not exceeding Rs. 500 + Applicable GST',
          width: 'half',
        },
        {
          name: 'Penal Interest Charges',
          value: 'Not exceeding 2.5% per month accrued on daily basis',
          width: 'full',
        },
        {
          name: 'Foreclosure criteria',
          value:
            'Loan cannot be foreclosed within 3 months from the Disbursement date',
          width: 'full',
        },
      ],
      DEFAULT: [],
    },
  },
  TAPSTART: {
    bankCode: 'TAPSTART',
    bankName: 'TapStart',
    bankNameAbbreviated: 'TAPSTART',
    mtBankImageUrl: require('@/assets/images/mt_tapstart_logo.png'),
    bankLogoUrl: require('@/assets/images/mt_tapstart_logo.png'),
    bankLogoUrlMonochrome:
      'https://placeholdit.imgix.net/~text?txtsize=33&txt=BankLogoMonochrome1&w=72&h=72',
    minimumAge: 21,
    maximumAge: 45,
    minimumIncome: 21000,
    repaymentConfig: {
      test: 'rzp_test_Eboav1PJGUZa7A',
      // test: 'rzp_live_Jyr5Z03jy2BAxx',
      live: 'rzp_live_Jyr5Z03jy2BAxx',
    },
    emandateConfig: {
      test: 'rzp_test_l8dZ0fFJvZHPCB',
      live: 'rzp_live_IoCyPUmyGzrVRp',
    },
    autoDebitConfig: {
      test: 'rzp_test_l8dZ0fFJvZHPCB',
      live: 'rzp_live_IoCyPUmyGzrVRp',
    },
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a \
  loan/credit card currently.',
        channelPartners: [
          {
            code: 'TAPSTART',
            name: 'TAPSTART',
            channel: 'IMPS',
          },
          {
            // TODO: change icons and images
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher ' +
                  'for one or multiple transactions on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted\
            to select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be\
            credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    supportedCities: [
      'Ahmedabad',
      'Aurangabad',
      'Bangalore',
      'Bhopal',
      'Bhubaneswar',
      'Chandigarh',
      'Chennai',
      'Coimbatore',
      'Delhi',
      'Faridabad',
      'Gurgaon',
      'Ghaziabad',
      'Greater Noida',
      'Guntur',
      'Gurgaon',
      'Guwahati',
      'Hyderabad',
      'Indore',
      'Jaipur',
      'Jodhpur',
      'Kanpur',
      'Kochi',
      'Lucknow',
      'Secunderabad',
      'Surat',
      'Kolkata',
      'Mumbai',
      'Mysuru',
      'Noida',
      'Nagpur',
      'Nashik',
      'Navi Mumbai',
      'New Delhi',
      'Pune',
      'Rajkot',
      'Thane',
      'Vadodara',
      'Vijayawada',
      'Vishakapatnam',
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    documentGroups: {
      id_proof: {
        pan_card: '',
        // aadhaar_card: '',
        voter_id: '',
        passport: '',
        driving_license: '',
        // govt_employee_id_card: ''
      },
      self_current_address_proof: {
        // aadhaar_card: '',
        passport: '',
        bank_statement: '',
        voter_id: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        water_bill: '',
        gas_connection: '',
        piped_gas_bill: '',
        driving_license: '',
        rental_agreement: '',
        // insurance_policy: '',
        // vehicle_registration_certificate: '',
        // employer_accomodation_allocation_letter: '',
        // municipal_tax_receipt: '',
        property_tax_receipt: '',
        // postoffice_account_statement: '',
        // postoffice_account_passbook: '',
        // employee_address_proof_letter: '',
        // nrega_job_card: '',
        // npr_letter: '',
        // pensioner_card: '',
        gas_bill: '',
        // govt_medical_insurance: ''
      },
    },
    emiInformation: {
      CASH_LINE: [
        {
          name: 'EMI Date / Repayment Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 500 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Interest Rate',
          value: 'Same as applicable interest rate',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value:
            'Basis the withdrawal amount: 299 for upto 3k, 549 for upto 5k, upto 1000 for 5k-25k, upto 5% for greater than or equal to 25K + applicable taxes',
          width: 'full',
        },
        {
          name: 'Pre-payment charges / Foreclosure charges',
          value: 'Up to 5 % on outstanding principal + applicable taxes',
          width: 'full',
        },
        {
          name: 'Penal Charges',
          value: '36% per annum on overdue principal amount + applicable GST',
          width: 'full',
        },
        {
          name: 'Online Facility',
          value: 'www.moneytap.com',
          width: 'full',
        },
        {
          name: 'Technology facilitator of online facility',
          value: 'MWYN Tech Private Limited (Moneytap)',
          width: 'full',
        },
      ],
      CASH_LINE_BOLT: [
        {
          name: 'EMI Date / Repayment Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 500 Per Dishonor + applicable taxes',
          width: 'half',
        },
        {
          name: 'Overdue Interest Rate',
          value: 'Same as applicable interest rate',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: 'Up to 8% of the withdrawal amount + applicable taxes',
          width: 'full',
        },
        {
          name: 'Pre-payment charges / Foreclosure charges',
          value: 'Up to 5 % on outstanding principal + applicable taxes',
          width: 'full',
        },
        {
          name: 'Penal Charges',
          value: '36% per annum on overdue principal amount + applicable GST',
          width: 'full',
        },
        {
          name: 'Online Facility',
          value: 'www.moneytap.com',
          width: 'full',
        },
        {
          name: 'Technology facilitator of online facility',
          value: 'MWYN Tech Private Limited (Moneytap)',
          width: 'full',
        },
      ],
      FAST_CASH: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Interest Rate',
          value:
            '2% Per Month on the amount of EMI outstanding as on that date',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: 'Basis the withdrawal amount: Rs. 799 to Rs. 2199 + GST',
          width: 'full',
        },
      ],
      CONSUMER_FINANCE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Interest Rate',
          value:
            '2% Per Month on the amount of EMI outstanding as on that date',
          width: 'full',
        },
      ],
      DEFAULT: [],
    },
  },
  CREDITSAISON: {
    bankCode: 'CREDITSAISON',
    bankName: 'Credit Saison',
    bankNameAbbreviated: 'CREDITSAISON',
    mtBankImageUrl: require('@/assets/images/mt_creditsaison_logo.png'),
    bankLogoUrl: require('@/assets/images/creditsaison_logo.png'),
    bankLogoUrlMonochrome:
      'https://placeholdit.imgix.net/~text?txtsize=33&txt=BankLogoMonochrome1&w=72&h=72',
    minimumAge: 23,
    maximumAge: 62,
    minimumIncome: 15000,
    repaymentConfig: {
      test: 'rzp_test_wKE2l9wNn9kYs7',
      live: 'rzp_live_QDyUm5M0Mx9lm8',
    },
    emandateConfig: {
      test: 'rzp_test_wKE2l9wNn9kYs7',
      live: 'rzp_live_QDyUm5M0Mx9lm8',
    },
    autoDebitConfig: {
      test: 'rzp_test_wKE2l9wNn9kYs7',
      live: 'rzp_live_QDyUm5M0Mx9lm8',
    },
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a\
    loan/credit card currently.',
        channelPartners: [
          {
            code: 'CREDITSAISON',
            name: 'CREDITSAISON',
            channel: 'IMPS',
          },
          {
            // TODO: change images and icons
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher for one or multiple transactions\
            on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have an \
          existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number \
          when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted to \
          select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be \
          credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    supportedCities: [
      'Ahmedabad',
      'Aurangabad',
      'Bangalore',
      'Bhopal',
      'Bhubaneswar',
      'Chandigarh',
      'Chennai',
      'Coimbatore',
      'Delhi',
      'Faridabad',
      'Gurgaon',
      'Ghaziabad',
      'Greater Noida',
      'Guntur',
      'Gurgaon',
      'Guwahati',
      'Hyderabad',
      'Indore',
      'Jaipur',
      'Jodhpur',
      'Kanpur',
      'Kochi',
      'Lucknow',
      'Secunderabad',
      'Surat',
      'Kolkata',
      'Mumbai',
      'Mysuru',
      'Noida',
      'Nagpur',
      'Nashik',
      'Navi Mumbai',
      'New Delhi',
      'Pune',
      'Rajkot',
      'Thane',
      'Vadodara',
      'Vijayawada',
      'Vishakapatnam',
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    documentGroups: {
      id_proof: {
        pan_card: '',
        // aadhaar_card: '',
        voter_id: '',
        passport: '',
        driving_license: '',
      },
      self_permanent_address_proof: {
        // aadhaar_card: '',
        passport: '',
        bank_statement: '',
        voter_id: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        water_bill: '',
        piped_gas_bill: '',
        telephone_bill: '',
        broadband_bill: '',
      },
      self_current_address_proof: {
        // aadhaar_card: '',
        passport: '',
        driving_license: '',
        bank_statement: '',
        voter_id: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        telephone_bill: '',
        municipal_tax_receipt: '',
        bank_passbook: '',
        piped_gas_bill: '',
        water_bill: '',
        gas_bill: '',
        rental_agreement: '',
      },
      relationship_proof: {
        marriage_certificate: '',
        birth_certificate: '',
        passport: '',
        pan_card: '',
        voter_id: '',
        // aadhaar_card: '',
        ration_card: '',
      },
      relative_current_address_proof: {
        // aadhaar_card: '',
        passport: '',
        bank_statement: '',
        voter_id: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        water_bill: '',
        gas_connection: '',
      },
    },
    emiInformation: {
      CASH_LINE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 500 Per Dishonor + applicable taxes',
          width: 'half',
        },
        {
          name: 'Overdue Interest Rate',
          value: 'Upto 3% per month',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value:
            'Based on disbursal amount: Not exceeding (Rs.299 for 3k; Rs.449 for 5k; Rs.699 for 10k; Rs.1000 for 15k-25k; 5% for >25k) + applicable taxes',
          width: 'full',
        },
        {
          name: 'Pre-payment charges/Foreclosure Charges',
          value: 'Up to 5% on outstanding principal + applicable taxes',
          width: 'full',
        },
        {
          name: 'Penal Charges',
          value: '36% per annum on overdue principal amount + applicable GST',
          width: 'full',
        },
      ],
      CASH_LINE_BOLT: [
        {
          name: 'EMI Date / Repayment Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 500 Per Dishonor + applicable taxes',
          width: 'half',
        },
        {
          name: 'Overdue Interest Rate',
          value: 'Upto 3% per month + applicable taxes',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: 'Up to 5 % of the withdrawal amount + applicable taxes',
          width: 'full',
        },
        {
          name: 'Pre-payment charges / Foreclosure charges',
          value: 'Up to 5% on outstanding principal + applicable taxes',
          width: 'full',
        },
        {
          name: 'Penal Charges',
          value: '36% per annum on overdue principal amount + applicable GST',
          width: 'full',
        },
      ],
      FAST_CASH: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 500 Per Dishonor + applicable taxes',
          width: 'half',
        },
        {
          name: 'Overdue Interest Rate',
          value: 'Same as applicable interest rate.',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: 'As per amount specified at sign up',
          width: 'full',
        },
        {
          name: 'Pre-payment charges/Foreclosure Charges',
          value: 'Up to 5% on outstanding principal + applicable taxes',
          width: 'full',
        },
      ],
      CONSUMER_FINANCE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value:
            '2% per month will be levied for number of days EMI payment is delayed.',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value:
            'Basis the withdrawal amount: 299 for 3K, 449 for 5K, 699 for 10K, 625 or \
          750 for 15K to 25K, 2.5% to 3% for greater than or equal to 25K',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value:
            'Up to 3% on outstanding principal + applicable taxes + overdue interest at 2% per month on unpaid EMI',
        },
      ],
      DEFAULT: [],
    },
  },
  HDBFS: {
    bankCode: 'HDBFS',
    bankName: 'HDB Financial Services',
    bankNameAbbreviated: 'HDBFS',
    mtBankImageUrl: require('@/assets/images/mt_hdbfs_logo.png'),
    bankLogoUrl: require('@/assets/images/hdbfs_logo.png'),
    bankLogoUrlMonochrome:
      'https://placeholdit.imgix.net/~text?txtsize=33&txt=BankLogoMonochrome1&w=72&h=72',
    minimumAge: 23,
    maximumAge: 62,
    minimumIncome: 15000,
    repaymentConfig: {
      test: 'rzp_test_POqVSKsREKhCli',
      live: 'rzp_live_rVw6oI9m5PJpG3',
    },
    emandateConfig: {
      test: 'rzp_test_0xwUPcqYkcuG2F',
      live: 'rzp_live_zVHFRr0JyJDiTS',
    },
    autoDebitConfig: {
      test: 'rzp_test_0xwUPcqYkcuG2F',
      live: 'rzp_live_zVHFRr0JyJDiTS',
    },
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a\
    loan/credit card currently.',
        channelPartners: [
          {
            code: 'HDBFS',
            name: 'HDBFS',
            channel: 'IMPS',
          },
          {
            // TODO: change images and icons
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher for one or multiple transactions\
            on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have an \
          existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number \
          when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted to \
          select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be \
          credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    supportedCities: [
      'Ahmedabad',
      'Aurangabad',
      'Bangalore',
      'Bhopal',
      'Bhubaneswar',
      'Chandigarh',
      'Chennai',
      'Coimbatore',
      'Delhi',
      'Faridabad',
      'Gurgaon',
      'Ghaziabad',
      'Greater Noida',
      'Guntur',
      'Gurgaon',
      'Guwahati',
      'Hyderabad',
      'Indore',
      'Jaipur',
      'Jodhpur',
      'Kanpur',
      'Kochi',
      'Lucknow',
      'Secunderabad',
      'Surat',
      'Kolkata',
      'Mumbai',
      'Mysuru',
      'Noida',
      'Nagpur',
      'Nashik',
      'Navi Mumbai',
      'New Delhi',
      'Pune',
      'Rajkot',
      'Thane',
      'Vadodara',
      'Vijayawada',
      'Vishakapatnam',
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    documentGroups: {
      id_proof: {
        pan_card: '',
        // aadhaar_card: '',
        voter_id: '',
        passport: '',
        driving_license: '',
      },
      self_permanent_address_proof: {
        // aadhaar_card: '',
        passport: '',
        voter_id: '',
        // bank_statement: '',
        // mobile_postpaid_bill: '',
        // electricity_bill: '',
        // water_bill: '',
        // piped_gas_bill: '',
        // telephone_bill: '',
        // broadband_bill: ''
      },
      self_current_address_proof: {
        // aadhaar_card: '',
        passport: '',
        driving_license: '',
        voter_id: '',
        bank_statement: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        property_tax_receipt: '',
        municipal_tax_receipt: '',
        pensioner_card: '',
        telephone_bill: '',
        employer_accommodation_allocation_letter: '',
        gazetted_officer_letter: '',
        bank_passbook: '',
        piped_gas_bill: '',
        water_bill: '',
        gas_bill: '',
        rental_agreement: '',
      },
      relationship_proof: {
        marriage_certificate: '',
        birth_certificate: '',
        passport: '',
        pan_card: '',
        voter_id: '',
        // aadhaar_card: '',
        ration_card: '',
      },
      relative_current_address_proof: {
        // aadhaar_card: '',
        passport: '',
        bank_statement: '',
        voter_id: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        water_bill: '',
        gas_connection: '',
      },
    },
    emiInformation: {
      CASH_LINE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 500 + GST Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value: '24%',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value:
            'Based on disbursal amount: Not exceeding (Rs.299 for 3k; Rs.449 for 5k; Rs.699 for 10k; Rs.1000 for 15k-25k; 5% for >25k) + applicable taxes',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value: 'Up to 5% on outstanding principal + applicable taxes',
        },
      ],
      CASH_LINE_BOLT: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 500 + GST Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value: '24%',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: 'Up to 5 % of the withdrawal amount + applicable taxes',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value: 'Up to 5% on outstanding principal + applicable taxes',
        },
      ],
      FAST_CASH: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 500 + GST per dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value: '24% p.a',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: 'Up to 3% of the withdrawal amount',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value: 'Up to 5% on outstanding principal + applicable taxes',
        },
      ],
      CONSUMER_FINANCE: [
        {
          name: 'EMI Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Charge',
          value:
            '2% per month will be levied for number of days EMI payment is delayed.',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value:
            'Basis the withdrawal amount: 299 for 3K, 449 for 5K, 699 for 10K, 625 or \
          750 for 15K to 25K, 2.5% to 3% for greater than or equal to 25K',
          width: 'full',
        },
        {
          name: 'Foreclosure Charges',
          value:
            'Up to 3% on outstanding principal + applicable taxes + overdue interest at 2% per month on unpaid EMI',
        },
      ],
      DEFAULT: [],
    },
  },
  LENDBOX: {
    bankCode: 'LENDBOX',
    bankName: 'Lendbox',
    bankNameAbbreviated: 'LENDBOX',
    mtBankImageUrl: require('@/assets/images/mt_lendbox_logo.png'),
    bankLogoUrl: require('@/assets/images/lendbox_standalone.svg'),
    bankLogoUrlMonochrome:
      'https://placeholdit.imgix.net/~text?txtsize=33&txt=BankLogoMonochrome1&w=72&h=72',
    minimumAge: 21,
    maximumAge: 45,
    minimumIncome: 21000,
    repaymentConfig: {
      test: 'rzp_test_Tm4MBNAbAua4ME',
      // test: 'rzp_live_Jyr5Z03jy2BAxx',
      live: 'rzp_live_3kJK15zlNncbE2',
    },
    emandateConfig: {
      test: 'rzp_test_10cqr1k83ifPpt',
      live: 'rzp_live_MK1gYGksB4SyYo',
    },
    autoDebitConfig: {
      test: 'rzp_test_10cqr1k83ifPpt',
      live: 'rzp_live_MK1gYGksB4SyYo',
    },
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a \
  loan/credit card currently.',
        channelPartners: [
          {
            code: 'TAPSTART',
            name: 'TAPSTART',
            channel: 'IMPS',
          },
          {
            // TODO: change icons and images
            code: 'AMAZON',
            name: 'Amazon',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Add money to your Amazon Pay balance',
            icon: '@IMAGE_LOCAL(cf_amazon)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'FLIPKART',
            name: 'Flipkart',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Shop on Flipkart for anything',
            icon: '@IMAGE_LOCAL(cf_flipkart_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have \
          an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'You can use it to pay during checkout under "Add/Pay By Gift Card" section',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You can use the entire amount in the voucher ' +
                  'for one or multiple transactions on Flipkart',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'You use the voucher code to pay for part of the order amount on Flipkart',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MAKE_MY_TRIP',
            name: 'MakeMyTrip',
            channel: 'QC_GIFT_VOUCHER',
            tagline: 'Book flight, hotels, holidays and more',
            icon: '@IMAGE_LOCAL(cf_mmt_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select your Flight/ Hotel and fill required details till you reach the payment page.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'On Website, click on "More options" and Select "Gift Card" as your Payment Mode.\
            On Android and IOS app, choose Gift Card as the payment option',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your Gift Card/ Card No. (16 Digit) and 6 Digit Pin No.',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Click on "Make Payment" and Pay the Balance amount (if any) using other Payment\
            Modes Listed.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'MYNTRA',
            name: 'Myntra',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Get all your favourite fashion brands & collections at the best prices',
            icon: '@IMAGE_LOCAL(cf_myntra)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Click on "Place Order"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Once you’ve reviewed and confirmed your address, click on "Proceed to Payment"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Select "Gift Card" from "Other Payment Options"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16 digit gift card code and 6 digit PIN',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Click on "Apply" to redeem the balance',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PEPPERFRY',
            name: 'Pepperfry',
            channel: 'QC_GIFT_VOUCHER',
            tagline:
              'Furnish your home with stylish decor & furniture on the Pepperfry website/app',
            icon: '@IMAGE_LOCAL(cf_pepper_fry_v2)',
            eligibility: [
              {
                description:
                  'An account with the merchant sites (Amazon, Flipkart, etc.)\nIf you don’t have\
            an existing account, just create one.',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description:
                  'Select the "Pepperfry Gift Card" option at the Choose Payment Method page',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'Enter your 16-digit Gift Card number and the corresponding 6-digit PIN number when prompted',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  "If the Gift Card value doesn't cover your Order total, you will be prompted\
            to select an additional payment method",
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description:
                  'If the Gift Card value is more than your Order total, the balance would be\
            credited back to your Gift Card and can be used towards your next purchase',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
          {
            code: 'PINE_LABS',
            name: 'View Brands/Products',
            channel: 'CHECKOUT',
            tagline: 'Best Products at a store near you',
            icon: '@IMAGE_LOCAL(ic_amazon)',
            eligibility: [
              {
                description: 'Offline store ',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
            steps: [
              {
                description: 'Generate voucher code using MoneyTap app',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Log in to your Amazon account',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to Amazon Pay',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Go to "Add Gift Card/Voucher Code"',
                icon: '@IMAGE_LOCAL(bg_radio_fill_white)',
              },
              {
                description: 'Amazon Pay balance will get credited instantly',
                icon: '@IMAGE_LOCAL(ic_ac_check_green_24dp)',
              },
            ],
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    documentGroups: {
      id_proof: {
        pan_card: '',
        // aadhaar_card: '',
        voter_id: '',
        passport: '',
        driving_license: '',
        // govt_employee_id_card: ''
      },
      self_current_address_proof: {
        // aadhaar_card: '',
        passport: '',
        bank_statement: '',
        voter_id: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        water_bill: '',
        gas_connection: '',
        piped_gas_bill: '',
        driving_license: '',
        rental_agreement: '',
        // insurance_policy: '',
        // vehicle_registration_certificate: '',
        // employer_accomodation_allocation_letter: '',
        // municipal_tax_receipt: '',
        property_tax_receipt: '',
        // postoffice_account_statement: '',
        // postoffice_account_passbook: '',
        // employee_address_proof_letter: '',
        // nrega_job_card: '',
        // npr_letter: '',
        // pensioner_card: '',
        gas_bill: '',
        // govt_medical_insurance: ''
      },
    },
    emiInformation: {
      CASH_LINE_BOLT: [
        {
          name: 'EMI Date / Repayment Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor + applicable taxes',
          width: 'half',
        },
        {
          name: 'Overdue Interest Charges/ Penal charges',
          value: 'Upto Rs 500 + applicable taxes',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: 'Up to 9% of the withdrawal amount + applicable taxes',
          width: 'full',
        },
        {
          name: 'Pre-payment charges / Foreclosure charges',
          value: 'Up to 5% on outstanding principal + applicable taxes',
          width: 'full',
        },
        {
          name: 'Penal Charges',
          value: '36.0% p.a. + applicable GST',
          width: 'full',
        },
      ],
      DEFAULT: [],
    },
  },
  CHOLAMANDALAM: {
    bankCode: 'CHOLAMANDALAM',
    bankName: 'Cholamandalam',
    bankNameAbbreviated: 'CHOLAMANDALAM',
    mtBankImageUrl: require('@/assets/images/mt_chola_logo.png'),
    bankLogoUrl: require('@/assets/images/mt_chola_logo.png'),
    bankLogoUrlMonochrome:
      'https://placeholdit.imgix.net/~text?txtsize=33&txt=BankLogoMonochrome1&w=72&h=72',
    minimumAge: 21,
    maximumAge: 45,
    minimumIncome: 21000,
    repaymentConfig: {
      test: 'rzp_test_FOcEPyakmi75LO',
      // test: 'rzp_live_rxxXbK1hmxTUAT',
      live: 'rzp_live_rxxXbK1hmxTUAT',
    },
    emandateConfig: {
      test: 'rzp_test_l0L3P2hP1EwAkE',
      live: 'rzp_live_o98SbBeYjacIjE',
    },
    autoDebitConfig: {
      test: 'rzp_test_l0L3P2hP1EwAkE',
      live: 'rzp_live_o98SbBeYjacIjE',
    },
    productOfferings: [
      {
        productCode: 'personal_loan',
        productName: 'Personal Loan',
        productTitle: 'Cash Loans',
        productDescription:
          'You can transfer the entire credit limit into your bank account.',
        productFeatures: [
          {
            title: 'No Usage, No Interest!',
            description:
              "Pay interest only on the amount you want to borrow. <b>No interest charged</b> if you don't\
        borrow any money from your credit line.",
            iconUrl: require('@/assets/images/ic_nuni.png'),
          },
          {
            title:
              '<font style="color: var(--primary)">Instant</font> Money Transfer',
            description:
              'Whenever you have a cash need, simply use the MoneyTap app to transfer money into\
        your bank account instantly.',
            iconUrl: require('@/assets/images/ic_instant_transfer.png'),
          },
          {
            title:
              'Limit Top-Up. <font style="color: var(--primary)">No paperwork</font>',
            description:
              'Whenever you close a loan, your limit gets an instant top-up for the same amount.\
        Use the money again.',
            iconUrl: require('@/assets/images/ic_topup.png'),
          },
        ],
        media: {
          mediaId: 'idfc_personal_loan_offering',
          mediaGroup: 'default_group',
        },
        referralConfig: {
          title: 'Refer MoneyTap to your friends',
          subtitle: 'Do you want to earn up to ₹1,00,000 in a few taps?',
          button: {
            title: 'Refer & Earn',
            subtitle: 'Win Amazon vouchers up to ₹1,00,000',
          },
        },
      },
      {
        productCode: 'consumer_finance',
        productName: 'Consumer Finance',
        productTitle: 'Consumer Finance',
        productDescription:
          'Unfortunately, none of our banking partners could approve your profile for a \
  loan/credit card currently.',
        channelPartners: [
          {
            code: 'CHOLAMANDALAM',
            name: 'CHOLAMANDALAM',
            channel: 'IMPS',
          },
        ],
        actions: [
          {
            code: 'interested',
            title: 'Complete your application',
            subtitle: 'and start shopping at No Cost EMI',
          },
          {
            code: 'notInterested',
            title: 'I want to borrow cash instead',
          },
          {
            code: 'withdrawal',
            title: 'Go Shopping',
          },
          {
            code: 'fund_transfer',
            title: 'Generate Voucher',
          },
        ],
      },
    ],
    supportedCities: [
      'Ahmedabad',
      'Aurangabad',
      'Bangalore',
      'Bhopal',
      'Bhubaneswar',
      'Chandigarh',
      'Chennai',
      'Coimbatore',
      'Delhi',
      'Faridabad',
      'Gurgaon',
      'Ghaziabad',
      'Greater Noida',
      'Guntur',
      'Gurgaon',
      'Guwahati',
      'Hyderabad',
      'Indore',
      'Jaipur',
      'Jodhpur',
      'Kanpur',
      'Kochi',
      'Lucknow',
      'Secunderabad',
      'Surat',
      'Kolkata',
      'Mumbai',
      'Mysuru',
      'Noida',
      'Nagpur',
      'Nashik',
      'Navi Mumbai',
      'New Delhi',
      'Pune',
      'Rajkot',
      'Thane',
      'Vadodara',
      'Vijayawada',
      'Vishakapatnam',
    ],
    educationQualificationMap: {
      Undergraduate: 'Undergraduate',
      Graduate: 'Graduate',
      Postgraduate: 'Postgraduate',
    },
    nationalityMap: {
      Indian: 'IND',
      Other: 'Other',
    },
    documentGroups: {
      id_proof: {
        pan_card: '',
        // aadhaar_card: '',
        voter_id: '',
        passport: '',
        driving_license: '',
        // govt_employee_id_card: ''
      },
      self_current_address_proof: {
        // aadhaar_card: '',
        passport: '',
        bank_statement: '',
        voter_id: '',
        mobile_postpaid_bill: '',
        electricity_bill: '',
        water_bill: '',
        gas_connection: '',
        piped_gas_bill: '',
        driving_license: '',
        rental_agreement: '',
        // insurance_policy: '',
        // vehicle_registration_certificate: '',
        // employer_accomodation_allocation_letter: '',
        // municipal_tax_receipt: '',
        property_tax_receipt: '',
        // postoffice_account_statement: '',
        // postoffice_account_passbook: '',
        // employee_address_proof_letter: '',
        // nrega_job_card: '',
        // npr_letter: '',
        // pensioner_card: '',
        gas_bill: '',
        // govt_medical_insurance: ''
      },
    },
    emiInformation: {
      CASH_LINE: [
        {
          name: 'EMI Date / Repayment Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Interest Rate',
          value: 'Same as applicable interest rate',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value:
            'Based on disbursal amount: Not exceeding (Rs.299 for 3k; Rs.449 for 5k; Rs.699 for 10k; Rs.1000 for Above 10k-25k; 5% for >25k) + applicable taxes',
          width: 'full',
        },
        {
          name: 'Pre-payment charges / Foreclosure charges',
          value: 'Up to 5% on outstanding principal + applicable taxes',
          width: 'full',
        },
        {
          name: 'Penal Charges',
          value:
            '36.0% p.a. on the amounts under default from the due date to the date of actual payment',
          width: 'full',
        },
      ],
      CASH_LINE_BOLT: [
        {
          name: 'EMI Date / Repayment Date',
          value: '5th of every month',
          width: 'half',
        },
        {
          name: 'Bounce Charges',
          value: 'Rs. 450 Per Dishonor',
          width: 'half',
        },
        {
          name: 'Overdue Interest Rate',
          value: 'Same as applicable interest rate',
          width: 'full',
        },
        {
          name: 'Processing Fees',
          value: 'Up to 7% of the withdrawal amount + applicable taxes',
          width: 'full',
        },
        {
          name: 'Pre-payment charges / Foreclosure charges',
          value: 'Up to 5% on outstanding principal + applicable taxes',
          width: 'full',
        },
        {
          name: 'Penal Charges',
          value:
            '36.0% p.a. on the amounts under default from the due date to the date of actual payment',
          width: 'full',
        },
      ],

      DEFAULT: [],
    },
  },
};
