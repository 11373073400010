
import { hasOwnProperty } from '@/lib/helpers';
import { Component, Vue, Prop } from 'vue-property-decorator';

interface NotificationObject {
  type: string;
  title?: string;
  message: string;
  showClose?: boolean;
  textColor?: string;
  bgColor?: string;
  autoClose?: number;
}

@Component
export default class Notification extends Vue {
  @Prop() private notificationData!: NotificationObject;
  private timer: any = false;

  private created() {
    let autoCloseTime = 10000;
    if (
      hasOwnProperty(this.notificationData, 'autoClose') &&
      this.notificationData.autoClose
    ) {
      autoCloseTime = this.notificationData.autoClose;
    }
    this.timer = setTimeout(() => {
      this.closeNotification();
    }, autoCloseTime);
  }

  private closeNotification() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.$emit('close-notification', { details: false });
  }
}
