import updeep from 'updeep';
import * as types from '../action_types/customer';
import { store } from '@/store/index';
import bus from '@/lib/event_bus';
import BigCookie from '@/business/models/big_cookie';
import {
  getCustomerData,
  buildProfile,
  updateBeneficiary,
  initiateEmandate,
  intimateEmandateSuccess,
  buildProfileV2,
  updateEmail,
} from '@/api';
import {
  initiateEmandate as initiateEmandateTask,
  intimateEmandateSuccess as initimateEmandateSuccessTask,
  updateApplicationData,
  updateBeneficiary as updateBeneficiaryTask,
} from '@/api/magic_link';
import { setLastBigCookieHitAt } from './app_config';
import { customerDetails } from '../getters/customer';
import { lastBigCookieHitAt } from '../getters/app_config';
import { FIVE_MINS_IN_MILLISECONDS } from '@/lib/consts';
import { getAllCookies, getBigCookie } from '@/lib/utils';
import { doCtxLogout } from './auth';
import { hasOwnProperty, isEmpty } from '@/lib/helpers';
import { defaultCatch } from '@/api/utils';
import { deleteLocalKey, setLocalKeyWithExpiry } from '../local_store';
import { CUSTOMER_DETAILS } from '../store_keys';

export function storeCustomerDetail(data: { payload: any }) {
  store.dispatch({
    type: types.STORE_CUSTOMER_DETAIL,
    payload: data.payload,
  });
  setLocalKeyWithExpiry(
    CUSTOMER_DETAILS,
    data.payload,
    FIVE_MINS_IN_MILLISECONDS
  );
}

export function clearCustomerDetails() {
  store.dispatch({
    type: types.CLEAR_CUSTOMER_DETAIL,
    payload: updeep.constant({ customerId: '' }),
  });
  deleteLocalKey(CUSTOMER_DETAILS);
}

export async function retrieveBigCookie(override = true, source = 'APP') {
  bus.$emit('syncEvent', 'WEB.BIG_COOKIE_REQUEST', {
    dateTime: new Date().toISOString(),
    source,
    override,
    nonInteraction: true,
  });
  if (canCallBigCookie(override)) {
    return new Promise((resolve, reject) => {
      getCustomerData()
        .then(async (response: any) => {
          const { status = 0 }: any = response;
          if (response.ok && status === 200) {
            storeCustomerDetail({
              payload: response.data,
            });
            setLastBigCookieHitAt({
              payload: new Date().getTime(),
            });
            BigCookie.getInstance().setBigCookie(response.data as BigCookie);
            resolve(response.data);
          }
        })
        .catch((error: any) => {
          reject(error);
          if (
            hasOwnProperty(error, 'status') &&
            [400, 502, 401].includes(error.status)
          ) {
            doCtxLogout(false);
          }
          console.log('Something went wrong. Please contact Freo', error);
        })
        .finally(() => {
          bus.$emit('loader');
        });
    });
  }
  return new Promise((resolve) => {
    resolve(customerDetails());
  });
}

export async function buildCustomerProfile(payload: any, customerId: string) {
  return new Promise((resolve, reject) => {
    buildProfile(payload, customerId)
      .then(async (response: any) => {
        const { status = 0 }: any = response;
        if (response.ok && status === 200) {
          try {
            const customerData = await retrieveBigCookie(
              true,
              'customer:buildCustomerProfile'
            );
            resolve(customerData);
          } catch (err) {
            console.log(
              'Something went wrong while retrieving your information',
              err
            );
            reject(err);
          }
        } else {
          console.log(
            'Something went wrong while retrieving your information',
            response
          );
          reject(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
        console.log(
          'Something went wrong while saving your information',
          error
        );
      });
  });
}

export async function buildCustomerProfileV2(payload: any) {
  return new Promise((resolve, reject) => {
    const { utm_source: utmSource = '' } = getAllCookies();
    buildProfileV2({
      ...payload,
      installMetadata: {
        utmCode: utmSource || process.env.VUE_APP_ID,
      },
    })
      .then((response: any) => {
        const { status = 0 } = response;
        if (response.ok && status === 200) {
          storeCustomerDetail({
            payload: response.data,
          });
          BigCookie.getInstance().setBigCookie(response.data as BigCookie);
          resolve(response);
        } else {
          console.log(
            'Something went wrong while retrieving your information',
            response
          );
          reject(response);
        }
      })
      .catch((error: any) => {
        reject(error);
        console.log(
          'Something went wrong while saving your information',
          error
        );
      });
  });
}

export function updateBeneficiaryDetails(payload: any) {
  return new Promise((resolve, reject) => {
    updateBeneficiary(payload)
      .then((response: any) => {
        const { status = 0 }: any = response;
        if (response.ok && status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
        bus.$emit('notify', {
          type: 'error',
          title: 'Error',
          message: 'Something went wrong while saving beneficiary details',
          autoClose: 5000,
        });
      });
  });
}

export async function initEmandate(payload: any) {
  return new Promise((resolve, reject) => {
    initiateEmandate(payload)
      .then((response: any) => {
        const { status = 0 }: any = response;
        if (response.ok && status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export async function handleEmandate(payload: any) {
  return new Promise((resolve, reject) => {
    intimateEmandateSuccess(payload)
      .then((response: any) => {
        const { status = 0 }: any = response;
        if (response.ok && status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export async function initEmandateTask(payload: any) {
  return new Promise((resolve, reject) => {
    initiateEmandateTask(payload)
      .then((response: any) => {
        const { status = 0 }: any = response;
        if (response.ok && status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export async function handleEmandateTask(payload: any) {
  return new Promise((resolve, reject) => {
    initimateEmandateSuccessTask(payload)
      .then((response: any) => {
        const { status = 0 }: any = response;
        if (response.ok && status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function updateBeneficiaryDetailsTask(payload: any) {
  return new Promise((resolve, reject) => {
    updateBeneficiaryTask(payload)
      .then((response: any) => {
        const { status = 0 }: any = response;
        if (response.ok && status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
        bus.$emit('notify', {
          type: 'error',
          title: 'Error',
          message: 'Something went wrong while saving beneficiary details',
          autoClose: 5000,
        });
      });
  });
}

export function updateOtherPendingDetails(payload: any) {
  return new Promise((resolve, reject) => {
    updateApplicationData(payload)
      .then((response: any) => {
        const { status = 0 }: any = response;
        if (response.ok && status === 200) {
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error: any) => {
        reject(error);
        bus.$emit('notify', {
          type: 'error',
          title: 'Error',
          message: 'Something went wrong while saving details',
          autoClose: 5000,
        });
      });
  });
}

export async function updateCustomerEmail(
  gmailSigninResponse: any,
  customerDetails: any,
  currentRoute: string = '',
  callbackFn?: Function | undefined
) {
  if (gmailSigninResponse.success) {
    const { userName, jwt } = gmailSigninResponse;
    if (!isEmpty(userName)) {
      try {
        await updateEmail({
          username: userName,
          password: jwt,
          authType: 'GMAIL',
        });
        getBigCookie(false);
        bus.$emit('syncEvent', 'WEB.GMAIL.LOGIN', {
          userName,
          nonInteraction: true,
        });
        bus.$emit(
          'syncEvent',
          `WEB.${currentRoute.toUpperCase()}.GMAIL.LOGIN`,
          {
            userName,
            nonInteraction: true,
            ...customerDetails,
          }
        );
        if (callbackFn != null) {
          callbackFn();
        }
      } catch (err) {
        console.log('Error while updating email: ', err);
        defaultCatch(err, { action: 'signing in with gmail' });
        bus.$emit(
          'syncEvent',
          `WEB.${currentRoute.toUpperCase()}.LOGIN_FAILURE`,
          {
            userName,
            nonInteraction: true,
          }
        );
      }
    }
  }
}

function canCallBigCookie(override: boolean) {
  const lastUpdatedAt = lastBigCookieHitAt();
  if (override || !lastUpdatedAt) {
    return true;
  }
  return new Date().getTime() - lastUpdatedAt > FIVE_MINS_IN_MILLISECONDS;
}
