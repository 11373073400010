import eventBus from '@/lib/event_bus';
import { get, isEmpty } from '@/lib/helpers';
import { isCustomerAuthenticatedSync } from '@/lib/login_utils';
import { isMicroappsEnabled } from '@/lib/utils';
import { retrieveBigCookie } from '@/store/actions/customer';
import { authState } from '@/store/getters/auth';
import bus from '@/lib/event_bus';
import { getNavigationRoute, updateLayoutConfig } from '@/lib/layout_config';
import { layoutConfig } from '@/lib/layout_consts';

const beforeEnter = async (to: any, from: any, next: any) => {
  if (isMicroappsEnabled()) {
    updateLayoutConfig('GPAY_LAYOUT');
  } else {
    updateLayoutConfig('DEFAULT_LAYOUT');
  }
  try {
    eventBus.$emit('loader', {
      show: true,
      message: 'Loading...',
    });
    if (isCustomerAuthenticatedSync(authState())) {
      await retrieveBigCookie(false, 'appRoutes:beforeEnter');
      bus.$emit('syncEvent', 'WEB.ROUTE_CHANGE', {
        to: to.name,
        from: from.name,
        nonInteraction: true,
      });
    }
    let navigationRoute;
    if (to && to.name) {
      navigationRoute = getNavigationRoute(undefined, undefined, to.name);
    } else {
      navigationRoute = getNavigationRoute();
    }
    const { targetRoute = '', query = {}, params = {} } = navigationRoute;
    if (targetRoute && targetRoute !== to.name) {
      next({
        name: targetRoute,
        query: !isEmpty(query) ? query : undefined,
        params: !isEmpty(params) ? params : undefined,
      });
    } else if (!isEmpty(query) || !isEmpty(params)) {
      next({
        ...to,
        ...(!isEmpty(query) && { query }),
        ...(!isEmpty(params) && { params }),
      });
    } else {
      next();
    }
  } catch (err) {
    console.log('Error while get customer details', err);
    next();
  } finally {
    eventBus.$emit('loader');
  }
};

export const appRoutes = {
  path: '/app',
  name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.aipHome', ''),
  component: () =>
    import(/* webpackChunkName: "dl-app-layout" */ '@/layouts/AppLayout.vue'),
  meta: { requiresAuth: true },
  beforeEnter,
  children: [
    {
      path: 'benefits',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.benefits', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-benefits" */ '@/views/BenefitsList.vue'
        ),
    },
    {
      path: 'beneficiary-details',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.beneficiaryDetails', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-bene-details" */ '@/views/BeneficiaryDetails.vue'
        ),
    },
    {
      path: 'upload-documents',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.uploadDocuments', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-upload-docs" */ '@/views/UploadDocuments.vue'
        ),
    },
    {
      path: 'submit-bank-statement',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.submitBankStatement', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-bank-statement" */ '@/views/SubmitBankStatement.vue'
        ),
    },
    {
      path: 'bank-formalities',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.bankFormalities', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-bank-formals" */ '@/views/BankFormalities.vue'
        ),
    },
    {
      path: 'office-address',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.officeAddress', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-off-addr" */ '@/views/OfficeAddress.vue'
        ),
    },
    {
      path: 'terms-and-conditions',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.termsAndConditions', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-tnc" */ '@/views/TermsAndConditions.vue'
        ),
    },
    {
      path: 'sign-documents-dmi',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.signDocumentsDmi', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-sign-dmi" */ '@/views/signDocuments/dmi/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-rbl',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.signDocumentsRbl', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-sign-rbl" */ '@/views/signDocuments/rbl/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-muskmelon',
      name: get(
        layoutConfig,
        'DEFAULT_LAYOUT.routes.signDocumentsMuskmelon',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-sign-mm" */
          '@/views/signDocuments/muskmelon/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-incred',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.signDocumentsIncred', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-sign-inc" */
          '@/views/signDocuments/incred/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-fullerton',
      name: get(
        layoutConfig,
        'DEFAULT_LAYOUT.routes.signDocumentsFullerton',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-sign-ful" */
          '@/views/signDocuments/fullerton/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-tapstart',
      name: get(
        layoutConfig,
        'DEFAULT_LAYOUT.routes.signDocumentsTapstart',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-sign-tapstart" */ '@/views/signDocuments/tapstart/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-lendbox',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.signDocumentsLendbox', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-sign-lendbox" */ '@/views/signDocuments/lendbox/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-cholamandalam',
      name: get(
        layoutConfig,
        'DEFAULT_LAYOUT.routes.signDocumentsCholamandalam',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-sign-cholamandalam" */ '@/views/signDocuments/cholamandalam/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-creditsaison',
      name: get(
        layoutConfig,
        'DEFAULT_LAYOUT.routes.signDocumentsCreditSaison',
        ''
      ),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-sign-cs" */
          '@/views/signDocuments/credit_saison/SignDocuments.vue'
        ),
    },
    {
      path: 'sign-documents-hdbfs',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.signDocumentsHdbfs', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-sign-hdbfs" */
          '@/views/signDocuments/hdbfs/SignDocuments.vue'
        ),
    },
    {
      path: 'interest-rate',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.interestRate', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-int-calc" */ '@/views/InterestRate.vue'
        ),
    },
    {
      path: 'key-fact-statement',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.keyFactStatement', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-int-calc" */ '@/views/KeyFactStatement.vue'
        ),
    },
    {
      path: 'application-status-tracker',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.appStatusTracker', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-app-track" */ '@/views/ApplicationStatusTracker.vue'
        ),
    },
    {
      path: 'e-mandate',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.emandate', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-mandate" */ '@/views/SetupEmandate.vue'
        ),
    },
    {
      path: 'okyc',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.okycOld', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-okyc-veri5" */ '@/views/OfflineKyc.vue'
        ),
    },
    {
      path: 'okyc/veri5',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.okyc', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-okyc-veri5" */ '@/views/OfflineKyc.vue'
        ),
    },
    {
      path: 'okyc/hyprvrg',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.hypervergeOkyc', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-hyperverge-okyc" */ '@/views/HypervergeOkyc.vue'
        ),
    },
    {
      path: 'complete-kyc',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.completeKyc', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-complete-kyc-landing" */ '@/views/CompleteKycLanding.vue'
        ),
    },
    {
      path: 'upload-selfie',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.uploadSelfie', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-upload-selfie" */ '@/views/UploadSelfie.vue'
        ),
    },
    {
      path: 'upload-salary-slips',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.uploadSalarySlips', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-upload-salary-slips" */ '@/views/UploadSalarySlips.vue'
        ),
    },
    {
      path: 'employment-stability-check',
      name: get(
        layoutConfig,
        'DEFAULT_LAYOUT.routes.employmentStabilityCheck',
        ''
      ),
      component: () =>
        import(
          /* webpackChunkName: "dl-employment-stability-check" */ '@/views/EmploymentStabilityCheck.vue'
        ),
    },
    {
      path: 'verify-income',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.verifyIncome', ''),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "dl-verify-income-1" */ '@/views/VerifyIncome.vue'
        ),
    },
    {
      path: 'schedule-appointment',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.scheduleAppointment', ''),
      component: () =>
        import(
          /* webpackChunkName: "dl-schedule-appointment" */ '@/views/ScheduleAppointment.vue'
        ),
    },
    {
      path: 'rbl-v-kyc',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.rblVkyc', ''),
      component: () =>
        import(/* webpackChunkName: "dl-rbl-vkyc" */ '@/views/RblVkyc.vue'),
    },
    {
      path: 'dkyc',
      name: get(layoutConfig, 'DEFAULT_LAYOUT.routes.dkyc', ''),
      props: true,
      component: () =>
        import(/* webpackChunkName: "dl-dkyc" */ '@/views/DigiKyc.vue'),
    },
  ],
};
