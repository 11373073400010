export const MIN_SALARY = 1;
export const MAX_SALARY = 999999;
export const AGE_LIMIT = {
  MIN: 23,
  MAX: 65,
};
export const ELIGIBLE_PARTNERS = [
  'RBL',
  'DMI',
  'INCRED',
  'FULLERTON',
  'HDBFS',
  'CREDITSAISON',
  'LENDBOX',
];
export const ELIGIBLE_LINE_TYPES = ['CASH_LINE'];
export const LINE_TYPES = {
  CF: 'CONSUMER_FINANCE',
  CL: 'CASH_LINE',
  FC: 'FAST_CASH',
};
export const SALARY_LIMITS = {
  MIN: 25000,
};
export const AIP_LINE_ELIGIBLE_PARTNERS = [
  'DMI',
  'RBL',
  'INCRED',
  'FULLERTON',
  'TAPSTART',
  'CREDITSAISON',
  'HDBFS',
  'MUSKMELON',
  'LENDBOX',
  'CHOLAMANDALAM',
];

export const IMPS_ELIGILBLE_PARTNERS = [
  'TAPSTART',
  'CHOLAMANDALAM',
  'CREDITSAISON',
  'INCRED',
  'LENDBOX',
];

export const IMPS_ELIGIBLE_LINE_TYPES = ['CASH_LINE', 'FAST_CASH'];

export const REPAYMENT_ELIGIBLE_PARTNERS = [
  'INCRED',
  'TAPSTART',
  'CREDITSAISON',
  'DMI',
  'MUSKMELON',
  'FULLERTON',
  'HDBFS',
];

export const CONFIN_ELIGIBLE_PARTNERS = ['DMI'];

export const HIDE_LSF_PARTNERS = ['CREDITSAISON', 'HDBFS'];

export const SHOW_CM_LAF_TNC_PARTNERS = [
  'CHOLAMANDALAM',
  'MUSKMELON',
  'HDBFS',
  'INCRED',
];

export const BENEFICIARY_BANK_LIST: Record<string, string> = {
  RBL: 'ALL',
  DMI: 'ALL',
  INCRED: 'ALL',
  FULLERTON: 'ALL',
  CREDITSAISON: 'ALL',
  HDBFS: 'ALL',
  MUSKMELON: 'EMANDATE',
  DEFAULT: 'ALL',
};

export const KFS_ELIGIBLE_PARTNERS = [
  'DMI',
  'INCRED',
  'FULLERTON',
  'HDBFS',
  'CREDITSAISON',
  'MUSKMELON',
  'LENDBOX',
  'CHOLAMANDALAM',
];
